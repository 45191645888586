import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAccordion.figmaUrl.android} codeUrl={checklists.componentAccordion.codeUrl.android} checklists={checklists.componentAccordion.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Accordion is a component that consists of a series of titles, and when you click on a specific title, the detailed description will be expanded. This will ensure that the user can focus on only one opened item at a time.`}</p>
    <p>{`The following is the format for using Legion Accordion`}</p>
    <h3>{`Basic`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.accordion.LgnAccordion
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:text="Item 1">
  <TextView
     android:layout_width="match_parent"
     android:layout_height="wrap_content"
     android:text="@string/foo_long"
     android:layout_marginHorizontal="@dimen/dimen_16dp"/>
</com.telkom.legion.component.accordion.LgnAccordion>
`}</code></pre>
    <br />
    <h3>{`Accordion with icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.accordion.LgnAccordion
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  app:startIconDrawable="@drawable/ic_calendar"
  android:text="Item 1">
  <TextView
     android:layout_width="match_parent"
     android:layout_height="wrap_content"
     android:text="@string/foo_long"
     android:layout_marginHorizontal="@dimen/dimen_16dp"/>
</com.telkom.legion.component.accordion.LgnAccordion>
`}</code></pre>
    <br />
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <h3>{`Accordion without divider`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.accordion.LgnAccordion
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:text="Item 3">
  <TextView
     android:layout_width="match_parent"
     android:layout_height="wrap_content"
     android:layout_marginHorizontal="@dimen/dimen_16dp"
     android:text="@string/foo_long" />
</com.telkom.legion.component.accordion.LgnAccordion>
`}</code></pre>
    <h3>{`Accordion with divider`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.accordion.LgnAccordion
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:text="Item 1"
  app:showSeparator="true"
  app:startIconDrawable="@drawable/ic_calendar">
  <TextView
     android:layout_width="match_parent"
     android:layout_height="wrap_content"
     android:layout_marginHorizontal="@dimen/dimen_16dp"
     android:text="@string/foo_long" />
</com.telkom.legion.component.accordion.LgnAccordion>
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set id for accordion`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Text value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Separator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:showSeparator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.showSeparator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show line separator between accordion`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Expanded`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isExpanded`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.isExpanded`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set expanded state on Accordion`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Show start Icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:startIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.startIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show start Icon on Accordion`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <div className="divi" />
    <h2>{`Example`}</h2>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`    <LinearLayout
        android:id="@+id/containerContent"
        android:layout_width="match_parent"
        android:layout_height="wrap_content"
        android:orientation="vertical">

        <com.telkom.legion.component.accordion.LgnAccordion
            android:id="@+id/accordion"
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:text="Legion Accordion tanpa separator">

            <TextView
                android:id="@+id/tv1"
                android:layout_width="match_parent"
                android:layout_height="match_parent"/>
        </com.telkom.legion.component.accordion.LgnAccordion>

        <com.telkom.legion.component.accordion.LgnAccordion
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:text="Legion Accordion dengan separator"
            app:showSeparator="true">

            <TextView
                android:id="@+id/tv2"
                android:layout_width="match_parent"
                android:layout_height="match_parent"/>
        </com.telkom.legion.component.accordion.LgnAccordion>

        <com.telkom.legion.component.button.outline.LgnPrimaryOutlineFitButton
            android:id="@+id/btnAction"
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:layout_marginTop="@dimen/dimen_16dp"
            android:layout_marginHorizontal="@dimen/dimen_16dp"
            android:text="Buka / Tutup Accordion"/>
    </LinearLayout>
`}</code></pre>
    <br />
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
...
override fun onViewCreated(view: View, savedInstanceState: Bundle?) {
        super.onViewCreated(view, savedInstanceState)
        with(binding) {
            val isExpand = MutableStateFlow(false)

            lifecycleScope.launch {
                isExpand.collectLatest { isExpanded ->
                    binding.containerContent.children.toList()
                        .filterIsInstance(LgnAccordion::class.java)
                        .onEach { it.isExpanded = isExpanded }
                }
            }
            tv1.setText("Lorem ipsum dolor sit amet, consectetur adipiscing elit. " +
                    "Phasellus vehicula posuere faucibus. Sed consectetur sapien magna, vitae gravida odio placerat vitae." +
                    " Donec euismod ultricies tortor, eu pretium massa molestie nec. " +
                    "Pellentesque tempus diam massa, eget congue orci feugiat vel. " +
                    "Mauris porttitor fermentum nibh, vel tincidunt neque fringilla non. " +
                    "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. "
                    )

            tv2.setText("Duis laoreet ipsum urna, sed placerat urna mattis eget. Donec quis faucibus orci. " +
                    "Quisque molestie tortor nec tincidunt aliquet. " +
                    "Donec id lorem laoreet, fringilla nisl vel, pulvinar sapien. " +
                    "Phasellus ac viverra odio, ornare luctus turpis. Morbi accumsan ut purus vitae tincidunt." +
                    " Curabitur eu auctor turpis. Fusce leo massa, pharetra a semper eget, luctus a eros. " +
                    "Fusce enim libero, dictum a risus sit amet, consequat consequat ante.")

            btnAction.setOnClickListener {
                btnAction.updateState(isExpand, !isExpand.value)
            }
        }
    }
...
...
`}</code></pre>
    <br />
    <br />
    <br />
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/accordion-android.gif",
            "width": 250,
            "height": 500,
            "align": "center",
            "alt": "accordion - android",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      